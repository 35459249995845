import React, { useState, useRef, useEffect, useMemo } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import { useMediaQuery, useTheme } from "@mui/material";
import DetailsModal from "./DetailsModal";
import InputMask from "react-input-mask";
import countryList from "react-select-country-list";
import { Routes, Route } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";

import { Fab } from "@mui/material";

import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
  FormGroup,
  Modal,
  Select,
  MenuItem,
  Divider,
  RadioGroup,
  Radio,
  Checkbox,
  FormControlLabel,
  FormLabel,
  InputLabel,
  FormControl,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@mui/material";

import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import {
  FaUser,
  FaUserPlus,
  FaSearch,
  FaCalendarAlt,
  FaUpload,
  FaCut,
  FaIdCard,
  FaFileUpload,
  FaStickyNote,
  FaEdit,
  FaCheck,
  FaInfoCircle,
} from "react-icons/fa";
import { Link as MuiLink } from "@mui/material";

import { jwtDecode } from "jwt-decode";
import tiposDeCabelo from '../assets/tiposdecabelo.png';

const RecipientRegistration = () => {
  const [activeTab, setActiveTab] = useState(null);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const [errorModalText, setErrorModalText] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const [faceErrorModalIsOpen, setFaceErrorModalIsOpen] = useState(false);
  const [faceErrorModalText, setFaceErrorModalText] = useState("");
  const db = getFirestore();

  const [jwtToken, setJwtToken] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [empresaId, setEmpresaId] = useState("");
  const [fetchError, setFetchError] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);
  const fileInputRef = useRef(null);
  const [cpfValue, setcpfValue] = useState("");
  const [fileModalOpen, setFileModalOpen] = useState(false);
  const [imageUrls, setImageUrls] = useState([null, null]);
  const [examFiles, setExamFiles] = useState([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [formData, setFormData] = useState({
    name: "",
    cpf: "",
    data_de_nascimento: "",
    raca: "",
    tipo_natural_do_cabelo: "",
    idade: "",
    tipo_sanguineo: "",
    altura: "",
    pesokg: "",
    cor_natural_do_cabelo: "",
    imc: "",
    email: "",
    cor_da_pele: "",
    cor_dos_olhos: "",
    n_do_prontuario: "",
    observacoes: "",
    empresa: "",
    cep: "",
    endereco: "",
    numero: "",
    complemento: "",
    telefone: "",
    pais: "",
  });
  const [editData, setEditData] = useState(null);
  const [docId, setDocId] = useState(null);
  const showModal = (title, text) => {
    setModalTitle(title);
    setModalText(text);
    setModalIsOpen(true);
  };

  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };
  const openFileModal = () => {
    setFileModalOpen(true);
  };

  const closeFileModal = () => {
    setFileModalOpen(false);
  };
  // Novo estado para controlar o modal de carregamento
  const [isLoading, setIsLoading] = useState(false);

  // Estado para armazenar as imagens selecionadas pelo usuário
  const [files, setFiles] = useState([]);

  const analyzeFacialFeatures = async (images, empresaId) => {
    const formData = new FormData();
    for (let i = 0; i < images.length; i++) {
      formData.append("images", images[i]);
    }

    // Inclua o empresaId no corpo da solicitação ou no cabeçalho, conforme necessário
    if (empresaId) {
      formData.append("empresaId", empresaId); // Se for pelo corpo do formulário
      // Ou use cabeçalhos se necessário
    }

    try {
      const response = await axios.post(
        "https://genematch.app/api/extract-landmarks-receptora",
        formData,
        {
          headers: {
            // 'Authorization': `Bearer ${jwtToken}` // Se estiver usando JWT no cabeçalho
            // 'empresaId': empresaId // Se estiver usando empresaId no cabeçalho
          },
        }
      );
      console.log("Resposta do servidor (Features):", response.data);
      return response.data;
    } catch (error) {
      console.error("Erro ao analisar as imagens:", error);
      return null;
    }
  };
  useEffect(() => {
    console.log("formData atualizado:", formData);
  }, [formData]);

  const handleNewConsultation = (data) => {
    setEditData(data);
    setDocId(data.id);
    setFormData({
      name: data.name || "",
      email: data.email || "",
      tipo_sanguineo: data.tipo_sanguineo || "",
      altura: data.altura || "",
      imc: data.imc || "",
      cor_da_pele: data.cor_da_pele || "",
      tipo_natural_do_cabelo: data.tipo_natural_do_cabelo || "",
      observacoes: data.observacoes || "",
      n_do_prontuario: data.n_do_prontuario || "",
      raca: data.raca || "",
      pesokg: data.pesokg || "",
      data_de_nascimento: data.data_de_nascimento || "",
      idade: data.idade || "",
      cor_natural_do_cabelo: data.cor_natural_do_cabelo || "",
      cor_dos_olhos: data.cor_dos_olhos || "",
      cpf: data.cpf || "",
      photoURLs_0: data.photoURLs ? data.photoURLs[0] || "" : "",
      photoURLs_1: data.photoURLs ? data.photoURLs[1] || "" : "",
      cep: data.cep || "",
      endereco: data.endereco || "",
      numero: data.numero || "",
      complemento: data.complemento || "",
      telefone: data.telefone || "",
      pais: data.pais || "",
    });
  };

  const validatePesokgInput = (event) => {
    // Usa uma regex para verificar se contém algo que não seja um dígito
    const invalidChars = /[^0-9]/g;
    if (event.target.value.match(invalidChars)) {
      event.target.value = event.target.value.replace(invalidChars, "");
    }
  };
  const handleInputAltura = (event) => {
    const value = event.target.value;
    const onlyNumbers = value.replace(/\D/g, "");

    if (onlyNumbers.length === 1) {
      event.target.value = `${onlyNumbers},`;
    } else if (onlyNumbers.length >= 2) {
      event.target.value = `${onlyNumbers.charAt(0)},${onlyNumbers.slice(
        1,
        3
      )}`;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    let formattedValue = value;

    // Para o campo email, converta para caixa baixa
    if (name === "email") {
      formattedValue = value.toLowerCase();
    }

    // Se for o campo pesokg, valide o valor
    else if (name === "pesokg") {
      formattedValue = value.replace(/\D/g, ""); // Remove qualquer coisa que não seja um dígito
    }

    // Se for o campo altura, formate o valor
    else if (name === "altura") {
      formattedValue = formatAltura(value);
    }

    // Se for o campo idade, valide o valor
    else if (name === "idade") {
      if (parseInt(value) > 99) {
        formattedValue = "99";
      }
    }

    // Se não for o campo de e-mail, converter o valor para caixa alta (caps lock)
    if (name !== "email") {
      formattedValue = formattedValue.toUpperCase();
    }

    // Se o campo for o CPF, formate o valor antes de atualizar o estado
    if (name === "cpf") {
      formattedValue = formatCPF(formattedValue);
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: formattedValue,
    }));

    if (name === "altura" || name === "pesokg") {
      calculateIMC(name, value);
    }
  };

  const formatAltura = (value) => {
    const onlyNumbers = value.replace(/\D/g, "");

    if (onlyNumbers.length === 1) {
      return `${onlyNumbers},`; // Adiciona vírgula automaticamente após o primeiro dígito
    } else if (onlyNumbers.length === 2) {
      return `${onlyNumbers.charAt(0)},${onlyNumbers.charAt(1)}`;
    } else if (onlyNumbers.length >= 3) {
      return `${onlyNumbers.charAt(0)},${onlyNumbers.slice(1, 3)}`;
    }
    return value;
  };

  const formatCPF = (value) => {
    // Remove todos os caracteres que não são dígitos
    const onlyNumbers = value.replace(/\D/g, "");

    // Aplica a formatação conforme o usuário digita
    if (onlyNumbers.length <= 3) {
      return onlyNumbers;
    } else if (onlyNumbers.length <= 6) {
      return `${onlyNumbers.slice(0, 3)}.${onlyNumbers.slice(3)}`;
    } else if (onlyNumbers.length <= 9) {
      return `${onlyNumbers.slice(0, 3)}.${onlyNumbers.slice(
        3,
        6
      )}.${onlyNumbers.slice(6)}`;
    } else {
      return `${onlyNumbers.slice(0, 3)}.${onlyNumbers.slice(
        3,
        6
      )}.${onlyNumbers.slice(6, 9)}-${onlyNumbers.slice(9, 11)}`;
    }
  };

  const calculateIMC = (name, value) => {
    if (name === "altura" || name === "pesokg") {
      const altura = name === "altura" ? value : formData.altura;
      const peso = name === "pesokg" ? value : formData.pesokg;

      if (altura && peso) {
        const alturaMetros = parseFloat(altura.replace(",", "."));
        const pesoKg = parseFloat(peso.replace(",", "."));

        if (
          !isNaN(alturaMetros) &&
          !isNaN(pesoKg) &&
          alturaMetros > 0 &&
          pesoKg > 0
        ) {
          const imc = (pesoKg / (alturaMetros * alturaMetros)).toFixed(2);
          setFormData((prevFormData) => ({
            ...prevFormData,
            imc,
          }));
        }
      }
    }
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles.length) {
      const newImageUrls = [];
      const newFiles = [];
      for (let i = 0; i < selectedFiles.length; i++) {
        const reader = new FileReader();
        reader.onloadend = () => {
          newImageUrls.push(reader.result);
          newFiles.push(selectedFiles[i]);
          if (newImageUrls.length === selectedFiles.length) {
            setImageUrls(newImageUrls);
            setFiles(newFiles);
          }
        };
        reader.readAsDataURL(selectedFiles[i]);
      }
    }
  };

  // Função para fechar o modal
  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    const jwtToken = localStorage.getItem("jwtToken");
    if (jwtToken) {
      const decodedToken = jwtDecode(jwtToken);
      setEmpresaId(decodedToken.empresaId);
    }
  }, []);

  const handleDelete = (index) => {
    // Verifica se o índice é válido
    if (index < 0 || index >= imageUrls.length) return;

    const newImageUrls = [...imageUrls]; // Clonar a lista atual de URLs
    newImageUrls.splice(index, 1); // Remove a imagem do índice específico
    setImageUrls(newImageUrls); // Atualizar o estado com a nova lista de URLs

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const fetchData = async (searchBy) => {
    try {
      const jwtToken = localStorage.getItem("jwtToken");

      if (!empresaId || empresaId === "") {
        console.error("empresaId está vazio ou indefinido.");
        return;
      }

      if (!jwtToken) {
        console.error(
          "Token JWT não encontrado. Certifique-se de que o usuário está autenticado."
        );
        return;
      }

      const decodedToken = jwtDecode(jwtToken);
      const empresaId = decodedToken.empresaId;

      const searchTerm = formData[searchBy].trim();
      if (!searchTerm) {
        console.error("O campo de busca está vazio.");
        return;
      }

      let fieldName, fieldValue;
      if (searchBy === "cpf") {
        fieldName = "cpf";
        fieldValue = searchTerm;
      } else if (searchBy === "n_do_prontuario") {
        fieldName = "n_do_prontuario";
        fieldValue = searchTerm;
      } else {
        console.error("Opção de busca inválida.");
        return;
      }

      const q = query(
        collection(db, `Empresas/${empresaId}/Receptoras`),
        where(fieldName, "==", fieldValue)
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const data = querySnapshot.docs[0].data();
        setEditData(data);
        setDocId(querySnapshot.docs[0].id);
        setFormData(data);
      } else {
        setEditData(null);
        console.log("Nenhum documento correspondente encontrado.");
      }
    } catch (error) {
      console.error("Erro ao buscar dados do cadastro:", error);
    }
  };
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [selectedRecipient, setSelectedRecipient] = useState(null);

  const closeDetailsModal = () => {
    setDetailsModalOpen(false);
    setSelectedRecipient(null);
  };
  // No useEffect, obtenha o token JWT e o armazene no estado
  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      const decodedToken = jwtDecode(token);
      setEmpresaId(decodedToken.empresaId);
      setJwtToken(token);
    }
  }, []);

  const handleFetchData = async (searchType, searchValue) => {
    setIsLoading(true);
    setFetchError(null); // Reset the error message

    if (!searchValue) {
      console.error("Por favor, forneça um CPF ou Nº do Prontuário.");
      setIsLoading(false);
      return;
    }

    const jwtToken = localStorage.getItem("jwtToken");
    if (!jwtToken) {
      console.error("JWT Token não encontrado.");
      setIsLoading(false);
      return;
    }

    const decodedToken = jwtDecode(jwtToken);
    const empresaId = decodedToken.empresaId;

    if (!empresaId) {
      console.error("empresaId está vazio ou indefinido.");
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.get(
        `https://genematch.app/api/search-receptora`,
        {
          headers: { empresaId },
          params: { [searchType]: searchValue },
        }
      );

      if (response.status === 200 && response.data) {
        console.log("Dados recebidos:", response.data);

        if (Array.isArray(response.data) && response.data.length > 0) {
          const recipientData = response.data[0];

          if (!recipientData.id) {
            console.error("Recipient data is missing an ID:", recipientData);
            setIsLoading(false);
            return;
          }

          setFormData((prevFormData) => ({
            ...prevFormData,
            ...recipientData,
          }));

          // Abre o modal de detalhes
          openDetailsModal(recipientData);

          // Aguarda um breve período para garantir que o modal de detalhes esteja pronto
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        } else {
          setFetchError("Cadastro inexistente.");
          setIsLoading(false);
        }
      } else {
        setFetchError("Cadastro inexistente.");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
      setFetchError("Erro ao buscar dados, inexistente ou incorreto.");
      setIsLoading(false);
    }
  };


  const openDetailsModal = (recipientData) => {
    if (recipientData && recipientData.id) {
      setSelectedRecipient(recipientData);
      setDetailsModalOpen(true);
    } else {
      console.error("Recipient data is missing an ID:", recipientData);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const jwtToken = localStorage.getItem("jwtToken");
    let empresaId = null;

    if (jwtToken) {
      const decodedToken = jwtDecode(jwtToken);
      empresaId = decodedToken.empresaId;

      if (!empresaId) {
        console.error("empresaId está vazio ou indefinido.");
        setIsLoading(false);
        setErrorModalText("Erro interno: Empresa ID não encontrado.");
        setErrorModalIsOpen(true);
        return;
      }
    }

    const combinedFormData = {
      ...formData,
      healthAnswers: JSON.stringify(healthAnswers), // Adicionar respostas de saúde ao formulário
      empresa: empresaId,
    };

    const formToSend = new FormData();
    for (let i = 0; i < files.length; i++) {
      formToSend.append("images", files[i]);
    }

    for (let i = 0; i < examFiles.length; i++) {
      formToSend.append("exams", examFiles[i]);
    }

    Object.keys(combinedFormData).forEach((key) => {
      formToSend.append(key, String(combinedFormData[key]));
    });

    try {
      const documentName = formData.name;
      const response = await axios.post(
        `https://genematch.app/api/extract-landmarks-receptora/${documentName}`,
        formToSend,
        { headers: { empresaId: empresaId } }
      );

      if (response.status === 200) {
        setModalText(
          "Prontinho, suas imagens, exames e respostas de saúde foram analisadas e seu cadastro foi efetuado com sucesso."
        );
        setModalIsOpen(true);
        setTimeout(() => {
          setModalIsOpen(false);
          setImageUrls([null, null]);
          setExamFiles([]);
          setExamFileNames([]);
          if (fileInputRef.current) {
            fileInputRef.current.value = null;
          }
          setFormData({
            name: "",
            email: "",
            tipo_sanguineo: "",
            altura: "",
            imc: "",
            cor_da_pele: "",
            tipo_natural_do_cabelo: "",
            observacoes: "",
            n_do_prontuario: "",
            raca: "",
            pesokg: "",
            data_de_nascimento: "",
            idade: "",
            cor_natural_do_cabelo: "",
            cor_dos_olhos: "",
            cpf: "",
            cep: "",
            endereco: "",
            numero: "",
            complemento: "",
            telefone: "",
            pais: "",
          });
          setFiles([]);
          setHealthAnswers({}); // Resetar as respostas de saúde
        }, 4000);
      } else {
        setModalText(
          "Resposta inesperada do servidor. Por favor, tente novamente mais tarde."
        );
        setModalIsOpen(true);
        setTimeout(() => setModalIsOpen(false), 4000);
      }
    } catch (error) {
      if (error.response) {
        console.error(
          "Erro de resposta:",
          error.response.status,
          error.response.data
        );
        setErrorModalText(
          `Erro no servidor: ${error.response.status}. Tente novamente mais tarde.`
        );
      } else if (error.request) {
        console.error("Erro de rede ou sem resposta:", error.request);
        setErrorModalText(
          "Erro de rede ou nenhum resposta do servidor. Verifique sua conexão com a internet."
        );
      } else {
        console.error("Erro ao configurar a requisição:", error.message);
        setErrorModalText("Erro ao processar a requisição. Tente novamente.");
      }
      setErrorModalIsOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCepChange = async (e) => {
    const cep = e.target.value.replace("-", "");

    // Atualizar o estado do CEP antes de validar
    setFormData((prevFormData) => ({
      ...prevFormData,
      cep: e.target.value,
    }));

    // Só valida e faz a busca se o CEP tiver 8 caracteres
    if (cep.length === 8 && !isNaN(cep)) {
      try {
        const response = await axios.get(
          `https://viacep.com.br/ws/${cep}/json/`
        );
        if (response.data) {
          const { logradouro, complemento, bairro, localidade, uf } =
            response.data;
          if (logradouro && localidade && uf) {
            console.log("Dados de endereço recebidos:", {
              logradouro,
              bairro,
              localidade,
              uf,
            });

            const address = `${logradouro}, ${
              bairro ? bairro + ", " : ""
            }${localidade}, ${uf}`;
            console.log("Endereço para geocodificação:", address);

            const getCoordinates = async (query) => {
              const nominatimResponse = await axios.get(
                `https://nominatim.openstreetmap.org/search`,
                {
                  params: {
                    q: query,
                    format: "json",
                    addressdetails: 1,
                    limit: 1,
                  },
                }
              );
              return nominatimResponse.data;
            };

            let nominatimResponseData = await getCoordinates(address);

            // Tentar com um endereço mais simples se a primeira tentativa falhar
            if (nominatimResponseData.length === 0) {
              const simpleAddress = `${localidade}, ${uf}`;
              console.warn(
                "Endereço completo não encontrado. Tentando com:",
                simpleAddress
              );
              nominatimResponseData = await getCoordinates(simpleAddress);
            }

            if (nominatimResponseData.length > 0) {
              const { lat, lon } = nominatimResponseData[0];

              setFormData((prevFormData) => ({
                ...prevFormData,
                endereco: logradouro,
                complemento,
                bairro,
                cidade: localidade,
                uf,
                latitude: lat,
                longitude: lon,
              }));

              console.log("Latitude e Longitude armazenadas:", { lat, lon });
            } else {
              console.error(
                "Erro: Nenhum resultado de geocodificação encontrado"
              );
            }
          } else {
            console.error("Erro: Dados de endereço incompletos", response.data);
          }
        } else {
          console.error("Erro: Resposta inválida da API ViaCEP");
        }
      } catch (error) {
        console.error("Erro ao buscar o CEP:", error);
      }
    } else if (cep.length === 8) {
      console.error("CEP inválido");
    }
  };

  const countryOptions = useMemo(() => countryList().getData(), []);

  const handleCountryChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      pais: event.target.value,
    }));
  };
  const handleDeleteExamFile = (index) => {
    const newExamFiles = [...examFiles];
    const newExamFileNames = [...examFileNames];
    newExamFiles.splice(index, 1);
    newExamFileNames.splice(index, 1);
    setExamFiles(newExamFiles);
    setExamFileNames(newExamFileNames);
  };
  const [examFileNames, setExamFileNames] = useState([]);
  const handleExamFileChange = (e) => {
    const newFiles = [...examFiles, ...e.target.files];
    setExamFiles(newFiles);
    const newFileNames = [
      ...examFileNames,
      ...Array.from(e.target.files).map((file) => file.name),
    ];
    setExamFileNames(newFileNames);
  };

  const handleOpenHealthQuestions = () => {
    setHealthQuestionsOpen(true);
  };

  const handleCloseHealthQuestions = () => {
    setHealthQuestionsOpen(false);
  };

  const [healthQuestionsOpen, setHealthQuestionsOpen] = useState(false);
  const [healthAnswers, setHealthAnswers] = useState({});
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    const fetchQuestions = async () => {
      const jwtToken = localStorage.getItem("jwtToken");
      if (jwtToken) {
        const decodedToken = jwtDecode(jwtToken);
        const empresaId = decodedToken.empresaId;

        if (!empresaId) {
          console.error("empresaId está vazio ou indefinido.");
          return;
        }

        try {
          const response = await axios.get(
            `https://genematch.app/api/questions?empresaId=${empresaId}`
          );
          setQuestions(response.data.questions || []);
        } catch (error) {
          console.error("Erro ao buscar perguntas:", error);
        }
      }
    };
    fetchQuestions();
  }, []);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Container maxWidth="false" sx={{ mt: 2, mb: 4, ml: 2, width: "100%" }}>

      <Box display="flex" flexDirection={isSmallScreen ? "column" : "row"}>
        <Sidebar setActiveTab={() => {}} />
        <Box
          component="main"
          flexGrow={1}
          p={2}
          sx={{
            width: "100%",
            ml: isSmallScreen ? 0 : -20,
            flexDirection: isSmallScreen ? "column" : "row",
          }}
        >
          <Divider sx={{ mb: 3 }} />
          <Box
            display="flex"
            mb={3}
            flexDirection={isSmallScreen ? "column" : "row"}
            alignItems={isSmallScreen ? "center" : "flex-start"}
          >
            <Box
              sx={{
                width: isSmallScreen ? "100%" : 150,
                height: isSmallScreen ? 200 : 150,
                mr: isSmallScreen ? 0 : 3,
                mb: isSmallScreen ? 2 : 0,
                border: "1px solid #ccc",
                borderRadius: 2,
                backgroundColor: "#f5f5f53d",
                ml: isSmallScreen ? 0 : 15,
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {imageUrls[0] ? (
                <>
                  <img
                    src={imageUrls[0]}
                    alt="Uploaded preview"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: 2,
                    }}
                  />
                  <Button
                    onClick={() => handleDelete(0)}
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      minWidth: "24px",
                      minHeight: "24px",
                      padding: "4px",
                      bgcolor: "rgba(255, 0, 0, 0.7)",
                      color: "#fff",
                      borderRadius: "50%",
                      "&:hover": {
                        bgcolor: "rgba(255, 0, 0, 0.9)",
                      },
                    }}
                  >
                    X
                  </Button>
                </>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  Foto
                </Typography>
              )}
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                onClick={openFileModal}
                onChange={(e) => handleFileChange(e)}
                className="form-control"
                style={{
                  display: "none",
                }}
                id="photo-upload-input"
              />
              <label
                htmlFor="photo-upload-input"
                style={{ position: "absolute", bottom: 10, right: 10 }}
              >
                <Fab color="primary" size="small" component="span">
                  <FaUpload />
                </Fab>
              </label>
            </Box>

            <Paper sx={{ p: 3, borderRadius: 2, boxShadow: 3, width: "100%" }}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      label="Nome"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <Box component="span" mr={1}>
                            <FaUser />
                          </Box>
                        ),
                      }}
                    />
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleNewConsultation}
                      sx={{ mt: 2 }}
                      startIcon={<FaUserPlus />}
                      fullWidth
                    >
                      Nova Consulta
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      label="CPF"
                      name="cpf"
                      maxLength={14}
                      value={formData.cpf}
                      onChange={handleChange}
                      required
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <Box component="span" mr={1}>
                            <FaIdCard />
                          </Box>
                        ),
                      }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleFetchData("cpf", formData.cpf)}
                      sx={{ mt: 2 }}
                      startIcon={<FaSearch />}
                      fullWidth
                    >
                      Pesquisar
                    </Button>
                    
                    {fetchError && (
                      <Typography color="error">{fetchError}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      label="Data de Nascimento"
                      name="data_de_nascimento"
                      type="date"
                      value={formData.data_de_nascimento}
                      onChange={handleChange}
                      required
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        startAdornment: (
                          <Box component="span" mr={1}>
                            <FaCalendarAlt />
                          </Box>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <FormControl fullWidth>
                      <InputLabel>País</InputLabel>
                      <Select
                        name="pais"
                        value={formData.pais}
                        onChange={handleCountryChange}
                        fullWidth
                      >
                        {countryOptions.map((country) => (
                          <MenuItem key={country.label} value={country.label}>
                            {country.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* Novos campos de endereço e telefone */}
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <InputMask
                      mask="99999-999"
                      value={formData.cep}
                      onChange={handleCepChange}
                    >
                      {() => (
                        <TextField label="CEP" name="cep" required fullWidth />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      label="Endereço"
                      name="endereco"
                      value={formData.endereco}
                      onChange={handleChange}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      label="Número"
                      name="numero"
                      value={formData.numero}
                      onChange={handleChange}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      label="Complemento"
                      name="complemento"
                      value={formData.complemento}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      label="Telefone"
                      name="telefone"
                      value={formData.telefone}
                      onChange={handleChange}
                      required
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <FormControl fullWidth required>
                      <InputLabel>Cor Natural do Cabelo</InputLabel>
                      <Select
                        name="cor_natural_do_cabelo"
                        value={formData.cor_natural_do_cabelo}
                        onChange={handleChange}
                      >
                        <MenuItem value="GRISALHOS">Grisalhos</MenuItem>
                        <MenuItem value="LOIROS">Loiros</MenuItem>
                        <MenuItem value="RUIVOS">Ruivos</MenuItem>
                        <MenuItem value="CASTANHOS">Castanhos</MenuItem>
                        <MenuItem value="PRETOS">Pretos</MenuItem>
                        <MenuItem value="BRANCO">Branco</MenuItem>
                      </Select>
                    </FormControl>
                    <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                      <FaCut style={{ marginRight: "8px", color: "#1976d2" }} />
                      <MuiLink
                        href="https://pt.wikipedia.org/wiki/Cor_dos_cabelos"
                        target="_blank"
                        rel="noopener"
                        sx={{
                          textDecoration: "none",
                          color: "#1976d2",
                          fontWeight: "bold",
                        }}
                      >
                        Sobre cores de cabelo
                      </MuiLink>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <FormControl fullWidth required>
                      <InputLabel>Raça</InputLabel>
                      <Select
                        name="raca"
                        value={formData.raca}
                        onChange={handleChange}
                        fullWidth
                      >
                        <MenuItem value="BRANCO">BRANCO</MenuItem>
                        <MenuItem value="NEGRO">NEGRO</MenuItem>
                        <MenuItem value="PARDO">PARDO</MenuItem>
                        <MenuItem value="AMARELO">AMARELO</MenuItem>
                        <MenuItem value="INDÍGENA">INDÍGENA</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <FormControl fullWidth required>
                      <InputLabel>Tipo Natural do Cabelo</InputLabel>
                      <Select
                        name="tipo_natural_do_cabelo"
                        value={formData.tipo_natural_do_cabelo}
                        onChange={handleChange}
                      >
                        <MenuItem value="1A LISOS (FINO)">1A LISOS (FINO)</MenuItem>
                        <MenuItem value="1B LISOS (MÉDIO)">1B LISOS (MÉDIO)</MenuItem>
                        <MenuItem value="1C LISOS (GROSSO)">1C LISOS (GROSSO)</MenuItem>
                        <MenuItem value="2A ONDULADOS (FINO)">2A ONDULADOS (FINO)</MenuItem>
                        <MenuItem value="2B ONDULADOS (MÉDIO)">2B ONDULADOS (MÉDIO)</MenuItem>
                        <MenuItem value="2C ONDULADOS (GROSSO)">2C ONDULADOS (GROSSO)</MenuItem>
                        <MenuItem value="3A CACHEADOS (SOLTO)">3A CACHEADOS (SOLTO)</MenuItem>
                        <MenuItem value="3B CACHEADOS (APERTADO)">3B CACHEADOS (APERTADO)</MenuItem>
                        <MenuItem value="4A CRESPOS (SOFT)">4A CRESPOS (SOFT)</MenuItem>
                        <MenuItem value="4B CRESPOS (WIRY)">4B CRESPOS (WIRY)</MenuItem>
                        <MenuItem value="4C CRESPOS (WIRY)">
                          4C Crespos (Wiry)
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                      <FaInfoCircle
                        style={{ marginRight: "8px", color: "#1976d2" }}
                      />
                      <MuiLink
                      component="button"
                        onClick={handleOpen}
                        sx={{
                          textDecoration: 'none',
                          color: '#1976d2',
                          fontWeight: 'bold',
                        }}
                      >
                        Sobre tipos de cabelo
                      </MuiLink>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      label="Idade"
                      name="idade"
                      type="number"
                      inputProps={{ min: "0", max: "99" }}
                      value={formData.idade}
                      onChange={handleChange}
                      required
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <FormControl fullWidth required>
                      <InputLabel>Tipo Sanguíneo</InputLabel>
                      <Select
                        name="tipo_sanguineo"
                        inputProps={{ min: "0", max: "99" }}
                        value={formData.tipo_sanguineo}
                        onChange={handleChange}
                        fullWidth
                      >
                        <MenuItem value="A+">A+</MenuItem>
                        <MenuItem value="A-">A-</MenuItem>
                        <MenuItem value="B+">B+</MenuItem>
                        <MenuItem value="B-">B-</MenuItem>
                        <MenuItem value="AB+">AB+</MenuItem>
                        <MenuItem value="AB-">AB-</MenuItem>
                        <MenuItem value="O+">O+</MenuItem>
                        <MenuItem value="O-">O-</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      label="Altura (em metros)"
                      name="altura"
                      value={formData.altura}
                      onInput={handleInputAltura}
                      onChange={handleChange}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      label="Peso (kg)"
                      name="pesokg"
                      value={formData.pesokg}
                      onInput={validatePesokgInput}
                      onChange={handleChange}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      label="IMC"
                      name="imc"
                      value={formData.imc}
                      onChange={handleChange}
                      required
                      fullWidth
                    />
                    <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                      <FaInfoCircle
                        style={{ marginRight: "8px", color: "#1976d2" }}
                      />
                      <MuiLink
                        href="https://pt.wikipedia.org/wiki/%C3%8Dndice_de_massa_corporal"
                        target="_blank"
                        rel="noopener"
                        sx={{
                          textDecoration: "none",
                          color: "#1976d2",
                          fontWeight: "bold",
                        }}
                      >
                        Mais informações sobre IMC
                      </MuiLink>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      label="Email"
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <FormControl fullWidth required>
                      <InputLabel>Cor da Pele</InputLabel>
                      <Select
                        name="cor_da_pele"
                        value={formData.cor_da_pele}
                        onChange={handleChange}
                      >
                        <MenuItem value="TIPO 1">Tipo I</MenuItem>
                        <MenuItem value="TIPO 2">Tipo II</MenuItem>
                        <MenuItem value="TIPO 3">Tipo III</MenuItem>
                        <MenuItem value="TIPO 4">Tipo IV</MenuItem>
                        <MenuItem value="TIPO 5">Tipo V</MenuItem>
                        <MenuItem value="TIPO 6">Tipo VI</MenuItem>
                      </Select>
                    </FormControl>
                    <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                      <FaInfoCircle
                        style={{ marginRight: "8px", color: "#1976d2" }}
                      />
                      <MuiLink
                        href="https://pt.wikipedia.org/wiki/Escala_de_Fitzpatrick"
                        target="_blank"
                        rel="noopener"
                        sx={{
                          textDecoration: "none",
                          color: "#1976d2",
                          fontWeight: "bold",
                        }}
                      >
                        Sobre a Escala de Fitzpatrick
                      </MuiLink>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <FormControl fullWidth required>
                      <InputLabel>Cor dos Olhos</InputLabel>
                      <Select
                        name="cor_dos_olhos"
                        value={formData.cor_dos_olhos}
                        onChange={handleChange}
                      >
                        <MenuItem value="ÍRIS AZUL-CLARA">
                          Íris azul-clara
                        </MenuItem>
                        <MenuItem value="ÍRIS AZUL-ESVERDEADA">
                          Íris azul-esverdeada
                        </MenuItem>
                        <MenuItem value="ÍRIS CINZA">Íris cinza</MenuItem>
                        <MenuItem value="ÍRIS AZUL-ESCURA">
                          Íris azul-escura
                        </MenuItem>
                        <MenuItem value="ÍRIS AZUL-CINZA COM MANCHAS AMARELAS/MARRONS">
                          Íris azul-cinza com manchas amarelas/marrons
                        </MenuItem>
                        <MenuItem value="ÍRIS CINZA-VERDE COM MANCHAS AMARELAS/MARRONS">
                          Íris cinza-verde com manchas amarelas/marrons
                        </MenuItem>
                        <MenuItem value="ÍRIS VERDE">Íris verde</MenuItem>
                        <MenuItem value="ÍRIS VERDE COM MANCHAS AMARELAS/MARRONS">
                          Íris verde com manchas amarelas/marrons
                        </MenuItem>
                        <MenuItem value="ÍRIS AVELÃ (CASTANHO-ESVERDEADA)">
                          Íris avelã (castanho-esverdeada)
                        </MenuItem>
                        <MenuItem value="ÍRIS ÂMBAR">Íris âmbar</MenuItem>
                        <MenuItem value="ÍRIS CASTANHO-CLARA">
                          Íris castanho-clara
                        </MenuItem>
                        <MenuItem value="ÍRIS CASTANHO-ESCURA">
                          Íris castanho-escura
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                      <FaInfoCircle
                        style={{ marginRight: "8px", color: "#1976d2" }}
                      />
                      <MuiLink
                        href="https://pt.wikipedia.org/wiki/Cor_dos_olhos"
                        target="_blank"
                        rel="noopener"
                        sx={{
                          textDecoration: "none",
                          color: "#1976d2",
                          fontWeight: "bold",
                        }}
                      >
                        Sobre a Cor dos Olhos
                      </MuiLink>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      label="Nº do Prontuário"
                      name="n_do_prontuario"
                      value={formData.n_do_prontuario}
                      onChange={(e) => {
                        handleChange(e);
                        fetchData("n_do_prontuario");
                      }}
                      fullWidth
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        handleFetchData(
                          "n_do_prontuario",
                          formData.n_do_prontuario
                        )
                      }
                      sx={{ mt: 2 }}
                      startIcon={<FaSearch />}
                      fullWidth
                    >
                      Pesquisar
                    </Button>
                    {fetchError && (
                      <Typography color="error">{fetchError}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Observações"
                      name="observacoes"
                      value={formData.observacoes}
                      onChange={handleChange}
                      fullWidth
                      multiline
                      rows={4}
                      InputProps={{
                        startAdornment: (
                          <Box component="span" mr={1}>
                            <FaStickyNote />
                          </Box>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection={isSmallScreen ? "column" : "row"}
                    >
                      <input
                        type="file"
                        accept=".pdf, image/jpeg"
                        multiple
                        onChange={handleExamFileChange}
                        className="form-control"
                        style={{
                          display: "none",
                        }}
                        id="exam-files-input"
                      />
                      <label htmlFor="exam-files-input">
                        <Button
                          variant="contained"
                          component="span"
                          startIcon={<FaFileUpload />}
                          style={{
                            width: isSmallScreen ? "100%" : "auto",
                            marginBottom: isSmallScreen ? 2 : 0,
                          }}
                        >
                          Anexar Exames
                        </Button>
                      </label>
                      <Tooltip title="Inclua exames em PDF ou JPEG aqui">
                        <IconButton>
                          <FaInfoCircle />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Box mt={2}>
                      {examFileNames.length > 0 && (
                        <Typography variant="body2">
                          {examFileNames.length} anexos escolhidos:
                        </Typography>
                      )}
                      <ul>
                        {examFileNames.map((name, index) => (
                          <li key={index}>
                            <Typography variant="body2">
                              {name}
                              <Button
                                onClick={() => handleDeleteExamFile(index)}
                                sx={{
                                  marginLeft: 2,
                                  color: "red",
                                }}
                              >
                                Remover
                              </Button>
                            </Typography>
                          </li>
                        ))}
                      </ul>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleOpenHealthQuestions}
                      fullWidth
                    >
                      Responder Perguntas de Saúde
                    </Button>
                  </Grid>

                  <DetailsModal
                    open={detailsModalOpen}
                    onClose={closeDetailsModal}
                    recipient={selectedRecipient}
                    jwtToken={jwtToken} // Passar o token JWT para o DetailsModal
                  />
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                      startIcon={isEditing ? <FaEdit /> : <FaCheck />}
                    >
                      {isEditing ? "Atualizar" : "Submeter"}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Box>
        </Box>
      </Box>
      <Modal
        open={healthQuestionsOpen}
        onClose={handleCloseHealthQuestions}
        aria-labelledby="health-questions-title"
        aria-describedby="health-questions-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isSmallScreen ? "90%" : "70%",
            maxWidth: 800,
            maxHeight: "90vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            overflowY: "auto", // Permitir rolagem vertical
          }}
        >
          <Typography id="health-questions-title" variant="h6" component="h2">
            Perguntas de Saúde
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Box component="form" noValidate autoComplete="off">
            <Grid container spacing={3}>
              {questions.map((question, index) => (
                <Grid item xs={12} key={index}>
                  {question.type === "multipleChoice" ? (
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend">{question.text}</FormLabel>
                      <FormGroup row>
                        {question.options.map((option, idx) => (
                          <FormControlLabel
                            key={idx}
                            control={
                              <Checkbox
                                checked={
                                  Array.isArray(healthAnswers[question.text]) &&
                                  healthAnswers[question.text].includes(option)
                                }
                                onChange={(e) => {
                                  const newAnswers = Array.isArray(
                                    healthAnswers[question.text]
                                  )
                                    ? [...healthAnswers[question.text]]
                                    : [];
                                  if (e.target.checked) {
                                    newAnswers.push(option);
                                  } else {
                                    const optionIndex =
                                      newAnswers.indexOf(option);
                                    if (optionIndex !== -1) {
                                      newAnswers.splice(optionIndex, 1);
                                    }
                                  }
                                  setHealthAnswers({
                                    ...healthAnswers,
                                    [question.text]: newAnswers,
                                  });
                                }}
                              />
                            }
                            label={option}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  ) : (
                    <TextField
                      label={question.text}
                      name={question.text}
                      value={healthAnswers[question.text] || ""}
                      onChange={(e) =>
                        setHealthAnswers({
                          ...healthAnswers,
                          [question.text]: e.target.value,
                        })
                      }
                      fullWidth
                      margin="normal"
                    />
                  )}
                </Grid>
              ))}
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Button
              variant="contained"
              color="primary"
              onClick={handleCloseHealthQuestions}
              sx={{ mt: 2 }}
              fullWidth
            >
              Salvar Respostas
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={isLoading} onClose={() => setIsLoading(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Estamos extraindo informações das imagens enviadas, aguarde...
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            outline: 0,
            borderRadius: 2,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Tipos de Cabelo
          </Typography>
          <Box
            component="img"
            src={tiposDeCabelo}
            alt="Tipos de cabelo"
            sx={{ width: '100%', mt: 2 }}
          />
        </Box>
      </Modal>
      <Modal open={isLoading} onClose={() => setIsLoading(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Pesquisando Cadastro...
          </Typography>
        </Box>
      </Modal>

      <Modal open={modalIsOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">{modalText}</Typography>
          {modalText.includes("Prontinho") && (
            <Button onClick={closeModal} variant="contained" sx={{ mt: 2 }}>
              OK
            </Button>
          )}
        </Box>
      </Modal>

      <Modal open={errorModalIsOpen} onClose={closeErrorModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Erro de Upload</Typography>
          <Typography>{errorModalText}</Typography>
          <Button
            onClick={closeErrorModal}
            variant="contained"
            color="error"
            sx={{ mt: 2 }}
          >
            Fechar
          </Button>
        </Box>
      </Modal>

      <Modal open={fileModalOpen} onClose={closeFileModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Qualidade da Imagem</Typography>
          <Typography>
            Por favor, inclua imagens de alta qualidade, bem iluminadas e com
            maior centralização facial, para que o sistema possa identificar o
            rosto e avaliar melhor as características.
          </Typography>
          <Button
            onClick={closeFileModal}
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Entendido
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

export default RecipientRegistration;
